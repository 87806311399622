// AbstractSpray

// ___________________________________________________________________

import * as React from 'react'
import styled from 'styled-components'
import theme from '../../gatsby-plugin-theme-ui'

// ___________________________________________________________________

const AbstractSpray = () => (
  <SVG width="100%" viewBox="0 0 426.3 651.91">
    <title>eOn abstract spray</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Isolation_Mode" data-name="Isolation Mode">
        <path
          className="cls-1"
          d="M0,650.94H85c186.13,0,337-150.9,337-337V253.8"
        />
        <path
          className="cls-2"
          d="M0,640.17H83.35c181.09,0,327.89-146.8,327.89-327.89V253.8"
        />
        <path
          className="cls-3"
          d="M0,629.4H81.72c176,0,318.75-142.71,318.75-318.75V253.8"
        />
        <path
          className="cls-4"
          d="M0,618.64H80.09c171,0,309.62-138.62,309.62-309.62V253.8"
        />
        <path
          className="cls-5"
          d="M0,607.87H78.46c166,0,300.48-134.53,300.48-300.48V253.8"
        />
        <path
          className="cls-6"
          d="M0,597.1H76.83c160.9,0,291.34-130.43,291.34-291.34v-52"
        />
        <path
          className="cls-7"
          d="M0,586.34H75.2c155.86,0,282.21-126.35,282.21-282.21V253.8"
        />
        <path
          className="cls-8"
          d="M0,575.57H73.57c150.81,0,273.07-122.26,273.07-273.07V253.8"
        />
        <path
          className="cls-9"
          d="M0,564.8H71.94c145.77,0,263.93-118.16,263.93-263.93V253.8"
        />
        <path
          className="cls-10"
          d="M0,554H70.31C211,554,325.11,440,325.11,299.24V253.8"
        />
        <path
          className="cls-11"
          d="M0,543.27H68.68c135.67,0,245.66-110,245.66-245.66V253.8"
        />
        <path
          className="cls-12"
          d="M0,532.5H67.05c130.63,0,236.52-105.89,236.52-236.52V253.8"
        />
        <path
          className="cls-13"
          d="M0,521.74H65.42c125.58,0,227.39-101.81,227.39-227.39V253.8"
        />
        <path
          className="cls-14"
          d="M0,511H63.79C184.33,511,282,413.26,282,292.73V253.8"
        />
        <path
          className="cls-15"
          d="M0,500.2H62.16c115.49,0,209.11-93.62,209.11-209.1V253.8"
        />
        <path
          className="cls-16"
          d="M0,489.44H60.54c110.44,0,200-89.53,200-200V253.8"
        />
        <path
          className="cls-17"
          d="M0,478.67H58.91c105.39,0,190.83-85.44,190.83-190.83v-34"
        />
        <path
          className="cls-18"
          d="M0,467.9H57.28A181.7,181.7,0,0,0,239,286.21V253.8"
        />
        <path
          className="cls-19"
          d="M0,457.14H55.65A172.56,172.56,0,0,0,228.21,284.58V253.8"
        />
        <path
          className="cls-20"
          d="M0,446.37H54A163.43,163.43,0,0,0,217.44,283V253.8"
        />
        <path
          className="cls-21"
          d="M0,435.6H52.39A154.28,154.28,0,0,0,206.67,281.32V253.8"
        />
        <path
          className="cls-22"
          d="M0,424.84H50.76A145.15,145.15,0,0,0,195.9,279.69V253.8"
        />
        <path
          className="cls-23"
          d="M0,414.07H49.13a136,136,0,0,0,136-136V253.8"
        />
        <path
          className="cls-24"
          d="M0,403.3H47.5A126.87,126.87,0,0,0,174.37,276.43V253.8"
        />
        <path
          className="cls-25"
          d="M0,392.54H45.87A117.74,117.74,0,0,0,163.6,274.8v-21"
        />
        <path
          className="cls-26"
          d="M0,381.77H44.24a108.6,108.6,0,0,0,108.6-108.6V253.8"
        />
        <path
          className="cls-27"
          d="M0,371H42.61a99.46,99.46,0,0,0,99.46-99.46V253.8"
        />
        <path
          className="cls-28"
          d="M0,360.24H41a90.32,90.32,0,0,0,90.32-90.33V253.8"
        />
        <path
          className="cls-29"
          d="M0,349.47H39.35a81.19,81.19,0,0,0,81.19-81.19V253.8"
        />
        <path
          className="cls-30"
          d="M0,338.7H37.72a72.05,72.05,0,0,0,72.05-72.05V253.8"
        />
        <path
          className="cls-31"
          d="M0,327.94H36.09A62.91,62.91,0,0,0,99,265V253.8"
        />
        <path
          className="cls-32"
          d="M0,317.17H34.46a53.78,53.78,0,0,0,53.78-53.78V253.8"
        />
        <path
          className="cls-33"
          d="M0,306.4H32.83a44.63,44.63,0,0,0,44.64-44.64v-8"
        />
        <path
          className="cls-34"
          d="M422.31,7.79a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78A3.93,3.93,0,0,1,422.31,0a3.86,3.86,0,0,1,4,3.92,3.78,3.78,0,0,1-1.12,2.73A3.83,3.83,0,0,1,422.31,7.79Z"
        />
        <path
          className="cls-34"
          d="M422.31,29.51a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.79,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.93,3.78,3.78,0,0,1-1.12,2.72A3.83,3.83,0,0,1,422.31,29.51Z"
        />
        <path
          className="cls-34"
          d="M422.31,51.23a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.72,3.81,3.81,0,0,1,1.14-2.79,3.93,3.93,0,0,1,2.9-1.14,3.83,3.83,0,0,1,2.87,1.14,3.87,3.87,0,0,1,1.12,2.79,3.78,3.78,0,0,1-1.12,2.72A3.83,3.83,0,0,1,422.31,51.23Z"
        />
        <path
          className="cls-34"
          d="M422.31,73a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.83,3.83,0,0,1,1.14-2.79,3.93,3.93,0,0,1,2.9-1.14,3.83,3.83,0,0,1,2.87,1.14,3.89,3.89,0,0,1,1.12,2.79,3.78,3.78,0,0,1-1.12,2.72A3.84,3.84,0,0,1,422.31,73Z"
        />
        <path
          className="cls-34"
          d="M422.31,94.68a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72A3.83,3.83,0,0,1,419.41,88a3.93,3.93,0,0,1,2.9-1.14A3.83,3.83,0,0,1,425.18,88a3.89,3.89,0,0,1,1.12,2.79,3.78,3.78,0,0,1-1.12,2.72A3.84,3.84,0,0,1,422.31,94.68Z"
        />
        <path
          className="cls-34"
          d="M422.31,116.4a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.78,3.9,3.9,0,0,1,2.9-1.15,3.8,3.8,0,0,1,2.87,1.15,3.85,3.85,0,0,1,1.12,2.78,3.78,3.78,0,0,1-1.12,2.72A3.84,3.84,0,0,1,422.31,116.4Z"
        />
        <path
          className="cls-34"
          d="M422.31,138.12a3.9,3.9,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.88,3.88,0,0,1-4,3.87Z"
        />
        <path
          className="cls-34"
          d="M422.31,159.84a3.93,3.93,0,0,1-2.9-1.14,3.74,3.74,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.9,3.9,0,1,1,0,7.79Z"
        />
        <path
          className="cls-34"
          d="M422.31,181.56a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.78,3.78,0,0,1-1.12,2.73A3.83,3.83,0,0,1,422.31,181.56Z"
        />
        <path
          className="cls-34"
          d="M422.31,203.28a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.78,3.78,0,0,1-1.12,2.73A3.83,3.83,0,0,1,422.31,203.28Z"
        />
        <path
          className="cls-34"
          d="M422.31,225a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.78,3.78,0,0,1-1.12,2.73A3.83,3.83,0,0,1,422.31,225Z"
        />
        <path
          className="cls-34"
          d="M385.4,7.79a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78A3.93,3.93,0,0,1,385.4,0a3.85,3.85,0,0,1,4,3.92,3.78,3.78,0,0,1-1.11,2.73A3.83,3.83,0,0,1,385.4,7.79Z"
        />
        <path
          className="cls-34"
          d="M385.4,29.51a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.79,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.93,3.77,3.77,0,0,1-1.11,2.72A3.83,3.83,0,0,1,385.4,29.51Z"
        />
        <path
          className="cls-34"
          d="M385.4,51.23a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.72,3.81,3.81,0,0,1,1.14-2.79,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.93,3.77,3.77,0,0,1-1.11,2.72A3.83,3.83,0,0,1,385.4,51.23Z"
        />
        <path
          className="cls-34"
          d="M385.4,73a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.83,3.83,0,0,1,1.14-2.79,3.93,3.93,0,0,1,2.9-1.14,3.83,3.83,0,0,1,2.87,1.14,3.88,3.88,0,0,1,1.11,2.79,3.77,3.77,0,0,1-1.11,2.72A3.84,3.84,0,0,1,385.4,73Z"
        />
        <path
          className="cls-34"
          d="M385.4,94.68a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72A3.83,3.83,0,0,1,382.5,88a3.93,3.93,0,0,1,2.9-1.14A3.83,3.83,0,0,1,388.27,88a3.88,3.88,0,0,1,1.11,2.79,3.77,3.77,0,0,1-1.11,2.72A3.84,3.84,0,0,1,385.4,94.68Z"
        />
        <path
          className="cls-34"
          d="M385.4,116.4a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.78,3.9,3.9,0,0,1,2.9-1.15,3.9,3.9,0,1,1,0,7.8Z"
        />
        <path
          className="cls-34"
          d="M385.4,138.12a3.9,3.9,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.86,3.86,0,0,1-4,3.87Z"
        />
        <path
          className="cls-34"
          d="M385.4,159.84a3.93,3.93,0,0,1-2.9-1.14,3.74,3.74,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.79,3.79,0,0,1-1.11,2.73A3.83,3.83,0,0,1,385.4,159.84Z"
        />
        <path
          className="cls-34"
          d="M385.4,181.56a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.78,3.78,0,0,1-1.11,2.73A3.83,3.83,0,0,1,385.4,181.56Z"
        />
        <path
          className="cls-34"
          d="M385.4,203.28a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.78,3.78,0,0,1-1.11,2.73A3.83,3.83,0,0,1,385.4,203.28Z"
        />
        <path
          className="cls-34"
          d="M385.4,225a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.78,3.78,0,0,1-1.11,2.73A3.83,3.83,0,0,1,385.4,225Z"
        />
        <path
          className="cls-34"
          d="M348.49,7.79a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78A3.93,3.93,0,0,1,348.49,0a3.85,3.85,0,0,1,4,3.92,3.78,3.78,0,0,1-1.11,2.73A3.83,3.83,0,0,1,348.49,7.79Z"
        />
        <path
          className="cls-34"
          d="M348.49,29.51a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.79,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.93,3.77,3.77,0,0,1-1.11,2.72A3.83,3.83,0,0,1,348.49,29.51Z"
        />
        <path
          className="cls-34"
          d="M348.49,51.23a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.72,3.81,3.81,0,0,1,1.14-2.79,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.93,3.77,3.77,0,0,1-1.11,2.72A3.83,3.83,0,0,1,348.49,51.23Z"
        />
        <path
          className="cls-34"
          d="M348.49,73a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.83,3.83,0,0,1,1.14-2.79,3.93,3.93,0,0,1,2.9-1.14,3.83,3.83,0,0,1,2.87,1.14,3.88,3.88,0,0,1,1.11,2.79,3.77,3.77,0,0,1-1.11,2.72A3.84,3.84,0,0,1,348.49,73Z"
        />
        <path
          className="cls-34"
          d="M348.49,94.68a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72A3.83,3.83,0,0,1,345.59,88a3.93,3.93,0,0,1,2.9-1.14A3.83,3.83,0,0,1,351.36,88a3.88,3.88,0,0,1,1.11,2.79,3.77,3.77,0,0,1-1.11,2.72A3.84,3.84,0,0,1,348.49,94.68Z"
        />
        <path
          className="cls-34"
          d="M348.49,116.4a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.78,3.9,3.9,0,0,1,2.9-1.15,3.9,3.9,0,1,1,0,7.8Z"
        />
        <path
          className="cls-34"
          d="M348.49,138.12a3.9,3.9,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.86,3.86,0,0,1-4,3.87Z"
        />
        <path
          className="cls-34"
          d="M348.49,159.84a3.93,3.93,0,0,1-2.9-1.14,3.74,3.74,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.79,3.79,0,0,1-1.11,2.73A3.83,3.83,0,0,1,348.49,159.84Z"
        />
        <path
          className="cls-34"
          d="M348.49,181.56a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.78,3.78,0,0,1-1.11,2.73A3.83,3.83,0,0,1,348.49,181.56Z"
        />
        <path
          className="cls-34"
          d="M348.49,203.28a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.78,3.78,0,0,1-1.11,2.73A3.83,3.83,0,0,1,348.49,203.28Z"
        />
        <path
          className="cls-34"
          d="M348.49,225a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.78,3.78,0,0,1-1.11,2.73A3.83,3.83,0,0,1,348.49,225Z"
        />
        <path
          className="cls-34"
          d="M311.58,7.79a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78A3.93,3.93,0,0,1,311.58,0a3.85,3.85,0,0,1,4,3.92,3.78,3.78,0,0,1-1.11,2.73A3.83,3.83,0,0,1,311.58,7.79Z"
        />
        <path
          className="cls-34"
          d="M311.58,29.51a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.79,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.93,3.77,3.77,0,0,1-1.11,2.72A3.83,3.83,0,0,1,311.58,29.51Z"
        />
        <path
          className="cls-34"
          d="M311.58,51.23a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.72,3.81,3.81,0,0,1,1.14-2.79,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.93,3.77,3.77,0,0,1-1.11,2.72A3.83,3.83,0,0,1,311.58,51.23Z"
        />
        <path
          className="cls-34"
          d="M311.58,73a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.83,3.83,0,0,1,1.14-2.79,3.93,3.93,0,0,1,2.9-1.14,3.83,3.83,0,0,1,2.87,1.14,3.88,3.88,0,0,1,1.11,2.79,3.77,3.77,0,0,1-1.11,2.72A3.84,3.84,0,0,1,311.58,73Z"
        />
        <path
          className="cls-34"
          d="M311.58,94.68a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72A3.83,3.83,0,0,1,308.68,88a3.93,3.93,0,0,1,2.9-1.14A3.83,3.83,0,0,1,314.45,88a3.88,3.88,0,0,1,1.11,2.79,3.77,3.77,0,0,1-1.11,2.72A3.84,3.84,0,0,1,311.58,94.68Z"
        />
        <path
          className="cls-34"
          d="M311.58,116.4a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.78,3.9,3.9,0,0,1,2.9-1.15,3.9,3.9,0,1,1,0,7.8Z"
        />
        <path
          className="cls-34"
          d="M311.58,138.12a3.9,3.9,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.86,3.86,0,0,1-4,3.87Z"
        />
        <path
          className="cls-34"
          d="M311.58,159.84a3.93,3.93,0,0,1-2.9-1.14,3.74,3.74,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.79,3.79,0,0,1-1.11,2.73A3.83,3.83,0,0,1,311.58,159.84Z"
        />
        <path
          className="cls-34"
          d="M311.58,181.56a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.78,3.78,0,0,1-1.11,2.73A3.83,3.83,0,0,1,311.58,181.56Z"
        />
        <path
          className="cls-34"
          d="M311.58,203.28a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.78,3.78,0,0,1-1.11,2.73A3.83,3.83,0,0,1,311.58,203.28Z"
        />
        <path
          className="cls-34"
          d="M311.58,225a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.78,3.78,0,0,1-1.11,2.73A3.83,3.83,0,0,1,311.58,225Z"
        />
        <path
          className="cls-34"
          d="M274.67,7.79a3.92,3.92,0,0,1-2.9-1.14,3.7,3.7,0,0,1-1.15-2.73,3.76,3.76,0,0,1,1.15-2.78A3.92,3.92,0,0,1,274.67,0a3.85,3.85,0,0,1,4,3.92,3.78,3.78,0,0,1-1.11,2.73A3.85,3.85,0,0,1,274.67,7.79Z"
        />
        <path
          className="cls-34"
          d="M274.67,29.51a3.92,3.92,0,0,1-2.9-1.14,3.69,3.69,0,0,1-1.15-2.72,3.77,3.77,0,0,1,1.15-2.79,3.92,3.92,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.93,3.77,3.77,0,0,1-1.11,2.72A3.85,3.85,0,0,1,274.67,29.51Z"
        />
        <path
          className="cls-34"
          d="M274.67,51.23a3.92,3.92,0,0,1-2.9-1.14,3.69,3.69,0,0,1-1.15-2.72,3.78,3.78,0,0,1,1.15-2.79,3.92,3.92,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.93,3.77,3.77,0,0,1-1.11,2.72A3.85,3.85,0,0,1,274.67,51.23Z"
        />
        <path
          className="cls-34"
          d="M274.67,73a3.93,3.93,0,0,1-2.9-1.15,3.69,3.69,0,0,1-1.15-2.72,3.8,3.8,0,0,1,1.15-2.79,3.92,3.92,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.93,3.77,3.77,0,0,1-1.11,2.72A3.86,3.86,0,0,1,274.67,73Z"
        />
        <path
          className="cls-34"
          d="M274.67,94.68a3.93,3.93,0,0,1-2.9-1.15,3.69,3.69,0,0,1-1.15-2.72A3.8,3.8,0,0,1,271.77,88a3.92,3.92,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.93,3.77,3.77,0,0,1-1.11,2.72A3.86,3.86,0,0,1,274.67,94.68Z"
        />
        <path
          className="cls-34"
          d="M274.67,116.4a3.93,3.93,0,0,1-2.9-1.15,3.69,3.69,0,0,1-1.15-2.72,3.76,3.76,0,0,1,1.15-2.78,3.89,3.89,0,0,1,2.9-1.15,3.86,3.86,0,0,1,4,3.93,3.77,3.77,0,0,1-1.11,2.72A3.86,3.86,0,0,1,274.67,116.4Z"
        />
        <path
          className="cls-34"
          d="M274.67,138.12a3.89,3.89,0,0,1-2.9-1.15,3.69,3.69,0,0,1-1.15-2.72,3.76,3.76,0,0,1,1.15-2.78,3.92,3.92,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.77,3.77,0,0,1-1.11,2.72A3.82,3.82,0,0,1,274.67,138.12Z"
        />
        <path
          className="cls-34"
          d="M274.67,159.84a3.92,3.92,0,0,1-2.9-1.14,3.71,3.71,0,0,1-1.15-2.73,3.76,3.76,0,0,1,1.15-2.78,3.92,3.92,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.79,3.79,0,0,1-1.11,2.73A3.85,3.85,0,0,1,274.67,159.84Z"
        />
        <path
          className="cls-34"
          d="M274.67,181.56a3.92,3.92,0,0,1-2.9-1.14,3.7,3.7,0,0,1-1.15-2.73,3.76,3.76,0,0,1,1.15-2.78,3.92,3.92,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.78,3.78,0,0,1-1.11,2.73A3.85,3.85,0,0,1,274.67,181.56Z"
        />
        <path
          className="cls-34"
          d="M274.67,203.28a3.92,3.92,0,0,1-2.9-1.14,3.7,3.7,0,0,1-1.15-2.73,3.76,3.76,0,0,1,1.15-2.78,3.92,3.92,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.78,3.78,0,0,1-1.11,2.73A3.85,3.85,0,0,1,274.67,203.28Z"
        />
        <path
          className="cls-34"
          d="M274.67,225a3.92,3.92,0,0,1-2.9-1.14,3.7,3.7,0,0,1-1.15-2.73,3.76,3.76,0,0,1,1.15-2.78,3.92,3.92,0,0,1,2.9-1.14,3.85,3.85,0,0,1,4,3.92,3.78,3.78,0,0,1-1.11,2.73A3.85,3.85,0,0,1,274.67,225Z"
        />
        <path
          className="cls-34"
          d="M237.75,7.79a4,4,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78A4,4,0,0,1,237.75,0a3.86,3.86,0,0,1,4,3.92,3.74,3.74,0,0,1-1.12,2.73A3.82,3.82,0,0,1,237.75,7.79Z"
        />
        <path
          className="cls-34"
          d="M237.75,29.51a4,4,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.79,4,4,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.93,3.74,3.74,0,0,1-1.12,2.72A3.82,3.82,0,0,1,237.75,29.51Z"
        />
        <path
          className="cls-34"
          d="M237.75,51.23a4,4,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.72,3.81,3.81,0,0,1,1.14-2.79,4,4,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.93,3.74,3.74,0,0,1-1.12,2.72A3.82,3.82,0,0,1,237.75,51.23Z"
        />
        <path
          className="cls-34"
          d="M237.75,73a4,4,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.83,3.83,0,0,1,1.14-2.79,4,4,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.93,3.74,3.74,0,0,1-1.12,2.72A3.83,3.83,0,0,1,237.75,73Z"
        />
        <path
          className="cls-34"
          d="M237.75,94.68a4,4,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72A3.83,3.83,0,0,1,234.85,88a4,4,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.93,3.74,3.74,0,0,1-1.12,2.72A3.83,3.83,0,0,1,237.75,94.68Z"
        />
        <path
          className="cls-34"
          d="M237.75,116.4a4,4,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.78,3.92,3.92,0,0,1,2.9-1.15,3.88,3.88,0,0,1,4,3.93,3.74,3.74,0,0,1-1.12,2.72A3.83,3.83,0,0,1,237.75,116.4Z"
        />
        <path
          className="cls-34"
          d="M237.75,138.12a3.92,3.92,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.78,4,4,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.74,3.74,0,0,1-1.12,2.72A3.79,3.79,0,0,1,237.75,138.12Z"
        />
        <path
          className="cls-34"
          d="M237.75,159.84a4,4,0,0,1-2.9-1.14,3.74,3.74,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,4,4,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.76,3.76,0,0,1-1.12,2.73A3.82,3.82,0,0,1,237.75,159.84Z"
        />
        <path
          className="cls-34"
          d="M237.75,181.56a4,4,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,4,4,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.74,3.74,0,0,1-1.12,2.73A3.82,3.82,0,0,1,237.75,181.56Z"
        />
        <path
          className="cls-34"
          d="M237.75,203.28a4,4,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,4,4,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.74,3.74,0,0,1-1.12,2.73A3.82,3.82,0,0,1,237.75,203.28Z"
        />
        <path
          className="cls-34"
          d="M237.75,225a4,4,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,4,4,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.74,3.74,0,0,1-1.12,2.73A3.82,3.82,0,0,1,237.75,225Z"
        />
        <path
          className="cls-34"
          d="M200.84,7.79a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78A3.93,3.93,0,0,1,200.84,0a3.86,3.86,0,0,1,4,3.92,3.74,3.74,0,0,1-1.12,2.73A3.82,3.82,0,0,1,200.84,7.79Z"
        />
        <path
          className="cls-34"
          d="M200.84,29.51a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.79,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.93,3.74,3.74,0,0,1-1.12,2.72A3.82,3.82,0,0,1,200.84,29.51Z"
        />
        <path
          className="cls-34"
          d="M200.84,51.23a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.72,3.81,3.81,0,0,1,1.14-2.79,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.93,3.74,3.74,0,0,1-1.12,2.72A3.82,3.82,0,0,1,200.84,51.23Z"
        />
        <path
          className="cls-34"
          d="M200.84,73a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.83,3.83,0,0,1,1.14-2.79,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.93,3.74,3.74,0,0,1-1.12,2.72A3.83,3.83,0,0,1,200.84,73Z"
        />
        <path
          className="cls-34"
          d="M200.84,94.68a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72A3.83,3.83,0,0,1,197.94,88a3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.93,3.74,3.74,0,0,1-1.12,2.72A3.83,3.83,0,0,1,200.84,94.68Z"
        />
        <path
          className="cls-34"
          d="M200.84,116.4a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.78,3.9,3.9,0,0,1,2.9-1.15,3.88,3.88,0,0,1,4,3.93,3.74,3.74,0,0,1-1.12,2.72A3.83,3.83,0,0,1,200.84,116.4Z"
        />
        <path
          className="cls-34"
          d="M200.84,138.12a3.9,3.9,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.74,3.74,0,0,1-1.12,2.72A3.79,3.79,0,0,1,200.84,138.12Z"
        />
        <path
          className="cls-34"
          d="M200.84,159.84a3.93,3.93,0,0,1-2.9-1.14A3.74,3.74,0,0,1,196.8,156a3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.76,3.76,0,0,1-1.12,2.73A3.82,3.82,0,0,1,200.84,159.84Z"
        />
        <path
          className="cls-34"
          d="M200.84,181.56a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.74,3.74,0,0,1-1.12,2.73A3.82,3.82,0,0,1,200.84,181.56Z"
        />
        <path
          className="cls-34"
          d="M200.84,203.28a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.74,3.74,0,0,1-1.12,2.73A3.82,3.82,0,0,1,200.84,203.28Z"
        />
        <path
          className="cls-34"
          d="M200.84,225a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.74,3.74,0,0,1-1.12,2.73A3.82,3.82,0,0,1,200.84,225Z"
        />
        <path
          className="cls-34"
          d="M163.93,7.79A3.93,3.93,0,0,1,161,6.65a3.72,3.72,0,0,1-1.14-2.73A3.79,3.79,0,0,1,161,1.14,3.93,3.93,0,0,1,163.93,0a3.86,3.86,0,0,1,4,3.92,3.78,3.78,0,0,1-1.12,2.73A3.83,3.83,0,0,1,163.93,7.79Z"
        />
        <path
          className="cls-34"
          d="M163.93,29.51a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.72A3.79,3.79,0,0,1,161,22.86a3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.93,3.78,3.78,0,0,1-1.12,2.72A3.83,3.83,0,0,1,163.93,29.51Z"
        />
        <path
          className="cls-34"
          d="M163.93,51.23a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.72A3.81,3.81,0,0,1,161,44.58a3.93,3.93,0,0,1,2.9-1.14,3.83,3.83,0,0,1,2.87,1.14,3.87,3.87,0,0,1,1.12,2.79,3.78,3.78,0,0,1-1.12,2.72A3.83,3.83,0,0,1,163.93,51.23Z"
        />
        <path
          className="cls-34"
          d="M163.93,73a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72A3.83,3.83,0,0,1,161,66.3a3.93,3.93,0,0,1,2.9-1.14,3.83,3.83,0,0,1,2.87,1.14,3.89,3.89,0,0,1,1.12,2.79,3.78,3.78,0,0,1-1.12,2.72A3.84,3.84,0,0,1,163.93,73Z"
        />
        <path
          className="cls-34"
          d="M163.93,94.68a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72A3.83,3.83,0,0,1,161,88a3.93,3.93,0,0,1,2.9-1.14A3.83,3.83,0,0,1,166.8,88a3.89,3.89,0,0,1,1.12,2.79,3.78,3.78,0,0,1-1.12,2.72A3.84,3.84,0,0,1,163.93,94.68Z"
        />
        <path
          className="cls-34"
          d="M163.93,116.4a3.94,3.94,0,0,1-2.9-1.15,3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.78,3.9,3.9,0,0,1,2.9-1.15,3.8,3.8,0,0,1,2.87,1.15,3.85,3.85,0,0,1,1.12,2.78,3.78,3.78,0,0,1-1.12,2.72A3.84,3.84,0,0,1,163.93,116.4Z"
        />
        <path
          className="cls-34"
          d="M163.93,138.12A3.9,3.9,0,0,1,161,137a3.72,3.72,0,0,1-1.14-2.72,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.88,3.88,0,0,1-4,3.87Z"
        />
        <path
          className="cls-34"
          d="M163.93,159.84a3.93,3.93,0,0,1-2.9-1.14,3.74,3.74,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.9,3.9,0,1,1,0,7.79Z"
        />
        <path
          className="cls-34"
          d="M163.93,181.56a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.78,3.78,0,0,1-1.12,2.73A3.83,3.83,0,0,1,163.93,181.56Z"
        />
        <path
          className="cls-34"
          d="M163.93,203.28a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.78,3.78,0,0,1-1.12,2.73A3.83,3.83,0,0,1,163.93,203.28Z"
        />
        <path
          className="cls-34"
          d="M163.93,225a3.93,3.93,0,0,1-2.9-1.14,3.72,3.72,0,0,1-1.14-2.73,3.79,3.79,0,0,1,1.14-2.78,3.93,3.93,0,0,1,2.9-1.14,3.86,3.86,0,0,1,4,3.92,3.78,3.78,0,0,1-1.12,2.73A3.83,3.83,0,0,1,163.93,225Z"
        />
      </g>
    </g>
  </SVG>
)

export default AbstractSpray

const SVG = styled.svg`
  .cls-1,
  .cls-10,
  .cls-11,
  .cls-12,
  .cls-13,
  .cls-14,
  .cls-15,
  .cls-16,
  .cls-17,
  .cls-18,
  .cls-19,
  .cls-2,
  .cls-20,
  .cls-21,
  .cls-22,
  .cls-23,
  .cls-24,
  .cls-25,
  .cls-26,
  .cls-27,
  .cls-28,
  .cls-29,
  .cls-3,
  .cls-30,
  .cls-31,
  .cls-32,
  .cls-33,
  .cls-4,
  .cls-5,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9 {
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 1.95px;
  }

  .cls-1 {
    stroke: #4172b2;
  }

  .cls-2 {
    stroke: #4776b4;
  }

  .cls-3 {
    stroke: #4d7bb7;
  }

  .cls-4 {
    stroke: #537fb9;
  }

  .cls-5 {
    stroke: #5984bc;
  }

  .cls-6 {
    stroke: #5f88be;
  }

  .cls-7 {
    stroke: #658cc0;
  }

  .cls-8 {
    stroke: #6b91c3;
  }

  .cls-9 {
    stroke: #7195c5;
  }

  .cls-10 {
    stroke: #769ac8;
  }

  .cls-11 {
    stroke: #7c9eca;
  }

  .cls-12 {
    stroke: #82a2cc;
  }

  .cls-13 {
    stroke: #88a7cf;
  }

  .cls-14 {
    stroke: #8eabd1;
  }

  .cls-15 {
    stroke: #94b0d4;
  }

  .cls-16 {
    stroke: #9ab4d6;
  }

  .cls-17 {
    stroke: #a0b9d9;
  }

  .cls-18 {
    stroke: #a6bddb;
  }

  .cls-19 {
    stroke: #acc1dd;
  }

  .cls-20 {
    stroke: #b2c6e0;
  }

  .cls-21 {
    stroke: #b8cae2;
  }

  .cls-22 {
    stroke: #becfe5;
  }

  .cls-23 {
    stroke: #c4d3e7;
  }

  .cls-24 {
    stroke: #cad7e9;
  }

  .cls-25 {
    stroke: #d0dcec;
  }

  .cls-26 {
    stroke: #d5e0ee;
  }

  .cls-27 {
    stroke: #dbe5f1;
  }

  .cls-28 {
    stroke: #e1e9f3;
  }

  .cls-29 {
    stroke: #e7edf5;
  }

  .cls-30 {
    stroke: #edf2f8;
  }

  .cls-31 {
    stroke: #f3f6fa;
  }

  .cls-32 {
    stroke: #f9fbfd;
  }

  .cls-33 {
    stroke: #fff;
  }

  .cls-34 {
    fill: #4172b2;
  }
`
